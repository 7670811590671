import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  Container,
  Table,
  FormGroup,
  Label,
  Input,
  Button,
  Badge
} from 'reactstrap';
import { Link } from 'react-router-dom';
import _ from 'lodash';
import { connect } from 'react-redux';
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Loader from 'react-loader-spinner';
import moment from 'moment';
import Paginator from '../Utility/Paginator';
import sendRequest, { setAuthToken } from '../../helpers/axios-setup';

const checkIsExpired = (given_date) => {
  return new Date() > new Date(given_date);
};

function TableHeader() {
  function CommonHeader() {
    return (
      <>
        <th>Shop Code</th>
        <th>Owner Name</th>
        <th>Phone Number</th>
        <th>Locality</th>
        {/* <th>API Key</th> */}
      </>
    );
  }
      return (
        <>
          <CommonHeader />
        </>
      );
}

function TableRow({item }) {
  function CommonColumn({item }) {
    return (
      <>
        <th>
          <Link
            to={`/tp/profile/view/${item._id}`}
          >
            {item.shop_data.shop_code}
          </Link>
        </th>
        <td>{item.name}</td>
        <td>{item.phone_number}</td>
        <td>{_.upperCase(item.shop_data.locality_name)}</td>
        {/* <td>{(item.apikey)}</td> */}
      </>
    );
  }

      return (
        <>
          <CommonColumn item={item} />
        </>
      )
}

const ThirdPartiesList = () => {
  const [breadcrumbItems] = useState([
    { title: 'Third Party', link: '#' },
    { title: 'Third Parties', link: '#' }
  ]);
  const [dataLoading, setDataLoading] = useState(true);
  const [data, setData] = useState([]);
  const [pagination, setPagination] = useState({
    currentPage: 1,
    pageLimit: 10,
    totalEntries: 0,
    totalPages: 0
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const [shopTypeFilter, setShopTypeFilter] = useState('kd_shops');
  const [membershipFilterParameter, setMembershipFilterParameter] =
    useState('');

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, []);

  const handlePagination = (page) => {
    fetchData(
      page,
      null,
      searchKeyword,
      shopTypeFilter,
      membershipFilterParameter
    );
  };

  const fetchData = async (
    current_page,
    page_limit,
    search_keyword,
  ) => {
    try {
      setDataLoading(true);
      setAuthToken();
      const { data: shop_data } = await sendRequest.get(
        `/tp?page=${current_page || pagination.currentPage}&limit=${
          page_limit || pagination.pageLimit}${search_keyword ? `&name_keyword=` + search_keyword : ''}`
      );

      setPagination({
        currentPage: shop_data.metadata.page,
        pageLimit: shop_data.metadata.limit,
        totalPages: shop_data.metadata.total_pages,
        totalEntries: shop_data.metadata.total_results
      });
      console.log(shop_data.results);
      setData(shop_data.results);
      setDataLoading(false);
    } catch (e) {
      console.log(e);
    }
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Third Parties" breadcrumbItems={breadcrumbItems} />
          <Row>
            <Col xs={12} >
              <Card>
                <CardBody>
                  <>
                    <Row className="mt-2 d-flex justify-content-between">
                      <Col md="2">
                        <FormGroup>
                          <Col md="12">
                            <Label className="col-form-label">
                              Show Entries
                            </Label>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                fetchData(
                                  1,
                                  e.target.value,
                                  searchKeyword,
                                );
                                setPagination({
                                  ...pagination,
                                  pageLimit: e.target.value
                                });
                              }}
                              value={pagination.pageLimit}
                              disabled={dataLoading}
                            >
                              <option value={10}>10</option>
                              <option value={25}>25</option>
                              <option value={50}>50</option>
                              <option value={100}>100</option>
                            </select>
                          </Col>
                        </FormGroup>
                        </Col>
                      <Col md="6">
                        <form
                          onSubmit={(e) => {
                            e.preventDefault();
                            fetchData(
                              1,
                              null,
                              searchKeyword
                            );
                          }}
                        >
                          <FormGroup className="d-flex justify-content-end">
                            <Col md="12">
                              <Row>
                                <Col md="8">
                                  <Label className="col-form-label">
                                    Search
                                  </Label>
                                  <Input
                                    className="form-control"
                                    type="text"
                                    onChange={(e) => {
                                      setSearchKeyword(e.target.value.trim());
                                    }}
                                    placeholder="Search Third Parties (by Name)"
                                  />
                                </Col>
                                <Col
                                  md="4"
                                  className="d-flex justify-content-center align-items-end"
                                >
                                  <Button
                                    className="btn btn-info"
                                    disabled={dataLoading}
                                    style={{ width: '100%' }}
                                  >
                                    <i className="ri-search-2-line align-middle mr-3"></i>
                                    {dataLoading ? 'Loading ...' : 'Search'}
                                  </Button>
                                </Col>
                              </Row>
                            </Col>
                          </FormGroup>
                        </form>
                      </Col>
                    </Row>
                    {dataLoading ? (
                      <div className="text-center">
                        <Loader
                          type="TailSpin"
                          color="#d3932c"
                          height={80}
                          width={80}
                        />
                      </div>
                    ) : (
                      <div className="table-rep-plugin">
                        <div
                          className="table-responsive mb-0"
                          data-pattern="priority-columns"
                        >
                          <Table
                            id="tech-companies-1"
                            striped
                            bordered
                            responsive
                          >
                            <thead>
                              <tr>
                                <TableHeader />
                              </tr>
                            </thead>
                            <tbody>
                              {data?.map((item, index) => (
                                <tr key={index}>
                                  <TableRow
                                    item={item}
                                  />
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    )}
                    <Row className="mt-2">
                      <Col md="5">
                        <p>
                          <strong>
                            {' '}
                            Showing{' '}
                            {(pagination.currentPage - 1) *
                              pagination.pageLimit +
                              1}{' '}
                            to{' '}
                            {Math.min(
                              pagination.currentPage * pagination.pageLimit,
                              pagination.totalEntries
                            )}{' '}
                            of {pagination.totalEntries} entries
                          </strong>
                        </p>
                      </Col>
                      {!dataLoading && data.length > 0 && (
                        <Col md="7">
                          <Paginator
                            totalPages={pagination.totalPages}
                            currentPage={pagination.currentPage}
                            changePage={handlePagination}
                          />
                        </Col>
                      )}
                    </Row>
                  </>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {};
};

export default connect(mapStateToProps)(ThirdPartiesList);
